import React, { useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import Button from "./Button";

const UserAccountsEditRow = ({
  type,
  email,
  emailOnChange,
  firstName,
  firstNameOnChange,
  lastName,
  lastNameOnChange,
  roles,
  rolesOnChange,
  handleCancelButton,
  handleSaveButton,
  emailAddressValid,
  emailAddressInUse,
  inputValid
}) => {
  return (
    <tr className="UserAccountsEditRow">
      {type === "add" ? (
        <React.Fragment>
          <td>
            <input
              className="UserAccountsEditRow_input"
              type="text"
              value={firstName}
              onChange={firstNameOnChange}
              placeholder="First name..."
            />
          </td>
          <td>
            <input
              className="UserAccountsEditRow_input"
              type="text"
              value={lastName}
              onChange={lastNameOnChange}
              placeholder="Last name..."
            />
          </td>
          <td>
            <input
              className="UserAccountsEditRow_input"
              type="email"
              value={email}
              onChange={emailOnChange}
              placeholder="Email address..."
            />
            {!emailAddressValid && (
              <p className="UserAccountsEditRow_error-message">
                Email address not valid
              </p>
            )}
            {emailAddressInUse && (
              <p className="UserAccountsEditRow_error-message">
                Email already in use
              </p>
            )}
          </td>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <td>{firstName}</td>
          <td>{lastName}</td>
          <td>{email}</td>
        </React.Fragment>
      )}

      <td style={{ overflow: "initial" }}>
        <Select
          inputId="criteria-modal-select-organisation"
          className="CriteriaModal_search-organisation-input"
          style={{ width: "3rem" }}
          classNamePrefix="CriteriaModal_search-organisation-input_"
          placeholder="Type to search"
          isRtl={false}
          isSearchable
          name="organisation"
          isMulti
          options={[
            {
              label: "Owner",
              value: "Owner"
            },
            {
              label: "Admin",
              value: "Admin"
            },
            {
              label: "SuperAdmin",
              value: "SuperAdmin"
            },
            {
              label: "User",
              value: "User"
            },
            {
              label: "Partner",
              value: "Partner"
            }
          ]}
          value={roles}
          onChange={event => {
            event.find(x => x.label === "Owner") === undefined
              ? rolesOnChange(event)
              : rolesOnChange([
                  {
                    label: "Owner",
                    value: "Owner"
                  }
                ]);
          }}
        />
      </td>
      <td>
        <Button onClick={handleCancelButton} label="Cancel" color="secondary" />{" "}
        <Button
          label="Save"
          onClick={handleSaveButton}
          disabled={!inputValid}
        />
      </td>
    </tr>
  );
};

UserAccountsEditRow.propTypes = {
  email: PropTypes.string.isRequired,
  emailOnChange: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  firstNameOnChange: PropTypes.func.isRequired,
  lastName: PropTypes.string.isRequired,
  lastNameOnChange: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  handleCancelButton: PropTypes.func.isRequired,
  handleSaveButton: PropTypes.func.isRequired,
  emailAddressValid: PropTypes.bool.isRequired,
  inputValid: PropTypes.bool.isRequired,
  emailAddressInUse: PropTypes.bool.isRequired
};

export default UserAccountsEditRow;
