import {
  FETCH_EXIST_ACCOUNT_LIST,
  CREATE_NEW_ACCOUNT,
  FETCH_ACCOUNT_DETAILS,
  REMOVE_ACCOUNT,
  UPDATE_ACCOUNT,
  UPDATE_PERSONAL_DETAILS,
  FETCH_ACCOUNT_PERMISSIONS,
  ASSOCIATE_ROLES_WITH_ACCOUNT,
  FETCH_ACCOUNT_OVERVIEW,
  CREATE_PARTNER_ACCOUNT,
  FETCH_PARTNER_ACCOUNTS,
  ASSIGN_REPORTS_TO_PARTNER_ACCOUNT,
  DELETE_LINKED_PARTNER_ACCOUNT,
  RESET_ACCOUNT_PASSWORD,
  CHANGE_PARTNER_ACCOUNT_OWNER,
  REQUEST_PASSWORD_RESET_EMAIL,
  SERVER_STATUS_CHECKING,
  CREATE_CLIENT,
  ADD_EXIST_ACCOUNT
} from '../constants';

export const fetchExistAccountList = (clientId) => ({
  type: FETCH_EXIST_ACCOUNT_LIST,
  clientId
});

export const addExistAccount =  (payload) => ({
  type: ADD_EXIST_ACCOUNT,
  payload
});

export const createNewAccount = payload => ({
  type: CREATE_NEW_ACCOUNT,
  payload,
});

export const fetchAccountDetails = accountId => ({
  type: FETCH_ACCOUNT_DETAILS,
  accountId,
});

export const createClient = payload => ({
  type: CREATE_CLIENT,
  payload
})

export const removeAccount = (accountId,clientId)  => ({
  type: REMOVE_ACCOUNT,
  accountId,
  clientId
});

export const updateAccount = (accountId,clientId, payload) => ({
  type: UPDATE_ACCOUNT,
  accountId,
  clientId,
  payload,
});

export const updatePersonalDetails = (accountId, payload) => ({
  type: UPDATE_PERSONAL_DETAILS,
  accountId,
  payload,
});

export const fetchAccountPermissions = () => ({
  type: FETCH_ACCOUNT_PERMISSIONS,
});

export const associateRolesWithAccount = (accountId, payload) => ({
  type: ASSOCIATE_ROLES_WITH_ACCOUNT,
  accountId,
  payload,
});

export const fetchAccountOverview = (clientId) => ({
  type: FETCH_ACCOUNT_OVERVIEW,
  clientId:clientId,
});

export const createPartnerAccount = (payload, reports) => ({
  type: CREATE_PARTNER_ACCOUNT,
  payload,
  reports,
});

export const fetchPartnerAccounts = () => ({
  type: FETCH_PARTNER_ACCOUNTS,
});

export const assignReportsToPartnerAccount = (clientId, payload) => ({
  type: ASSIGN_REPORTS_TO_PARTNER_ACCOUNT,
  clientId,
  payload,
});

export const deleteLinkedPartnerAccount = clientId => ({
  type: DELETE_LINKED_PARTNER_ACCOUNT,
  clientId,
});

export const resetAccountPassword = (token, payload) => ({
  type: RESET_ACCOUNT_PASSWORD,
  token,
  payload,
});

export const changePartnerAccountOwner = (clientId, accountId) => ({
  type: CHANGE_PARTNER_ACCOUNT_OWNER,
  clientId,
  payload: {
    accountId,
  }
});

export const requestPasswordResetEmail = username => ({
  type: REQUEST_PASSWORD_RESET_EMAIL,
  payload: {
    username,
  },
})

export const checkServerStatus = () => ({
  type: SERVER_STATUS_CHECKING,
})



