import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Select from "react-select";

import {
  createNewAccount,
  updateAccount,
  removeAccount,
  fetchAccountOverview,
  fetchExistAccountList,
  addExistAccount
} from "../store/actions/account-actions";

import { checkEmailAddress, formatSelection } from "../utilities/helpers";

import LoadingContainer from "../components/LoadingContainer";
import SVGIcon from "../components/SVGIcon";
import Button from "../components/Button";
import UserAccountsEditRow from "../components/UserAccountsEditRow";

const mapStateToProps = ({ account }) => {
  const filteredAccounts =
    account.accountOverviewLoaded && account.accountOverviewData.accounts
      ? account.accountOverviewData.accounts.filter(
          item =>
            item.username !== account.accountOverviewData.ownerAccount.username
        )
      : [];

  return {
    loading: !account.accountOverviewLoaded,
    accounts: filteredAccounts,
    ownerAccount: account.accountOverviewData.ownerAccount || {},
    existAccount: account.existAccount,
    accountEmailAddressesInUse: filteredAccounts.map(item => item.username)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createNewAccount,
      updateAccount,
      removeAccount,
      fetchAccountOverview,
      fetchExistAccountList,
      addExistAccount
    },
    dispatch
  );

class UserAccounts extends React.Component {
  state = {
    editRow: null,
    addRow: false,
    editedFirstName: "",
    editedLastName: "",
    editedEmailAddress: "",
    editedRoles: [],
    isRoleChange: false,
    emailAddressValid: false,
    editExistAccount: null,
    assignRole: null,
    modalOpen: false
  };

  componentDidMount() {
    this.props.fetchAccountOverview(this.props.currentClientId);
  }

  handleAddUserButton = () => {
    this.setState(() => ({
      addRow: true,
      editRow: null,
      editedFirstName: "",
      editedLastName: "",
      editedRoles: [],
      editedEmailAddress: "",
      emailAddressValid: true
    }));
  };

  handleEditButton = index => {
    this.setState(() => ({
      editRow: index,
      addRow: false,
      editedFirstName: this.props.accounts[index].user.firstName,
      editedLastName: this.props.accounts[index].user.lastName,
      editedEmailAddress: this.props.accounts[index].username,
      editedRoles: formatSelection(this.props.accounts[index].role.roles),
      emailAddressValid: checkEmailAddress(this.props.accounts[index].username)
    }));
  };

  handleExistsAccount = e => {
    this.setState(() => ({ editExistAccount: e}));
  }
  handleAddExistAccountButton = () =>{

    let payloadArray = [];

    this.state.editExistAccount.map((item)=>{
      payloadArray.push({
        id: item.value,
        clients: [
          {
            id: this.props.currentClientId ,
            roles:this.state.assignRole.map((item)=>{
              return item.label
          }),

           }
        ]
      })
    })

  this.props.addExistAccount(payloadArray);
  this.closeModal();
  this.reGettingtheData();
  }


  reGettingtheData = () =>{
    setTimeout(
      function() {
        this.props.fetchAccountOverview(this.props.currentClientId);
      }.bind(this),
      1000
    );
  }


  handleAssignRole = e => {
    this.setState(() => ({ assignRole: e}));
  }

  handleFirstNameOnChange = e => {
    const editedFirstName = e.target.value;
    this.setState(() => ({ editedFirstName }));
  };

  handleLastNameOnChange = e => {
    const editedLastName = e.target.value;
    this.setState(() => ({ editedLastName }));
  };

  handleRolesOnChange = e => {
    const editedRoles = e;
    this.setState(() => ({ editedRoles, isRoleChange: true }));
  };

  handleEmailOnChange = e => {
    const editedEmailAddress = e.target.value;
    this.setState(() => ({
      editedEmailAddress,
      emailAddressValid: checkEmailAddress(editedEmailAddress)
    }));
  };

  handleCancelButton = () => {
    this.setState(() => ({ editRow: null }));
  };

  handleCancelAddUserButton = () => {
    this.setState(() => ({ addRow: false }));
  };

  handleSaveAddUserButton = () => {
    this.setState(() => ({ addRow: false }));

    if (
      this.state.editedFirstName !== "" &&
      this.state.editedLastName !== "" &&
      checkEmailAddress(this.state.editedEmailAddress) === true
    ) {

      this.props.createNewAccount({
        clientId: this.props.currentClientId,
        username: this.state.editedEmailAddress,
        user: {
          firstName: this.state.editedFirstName,
          lastName: this.state.editedLastName,
        },
        roles: this.state.editedRoles.find(x => x.label === "Owner") !== undefined
          ? ["Owner"]
          : this.state.editedRoles.map(item => {
              return item.value;
            })
      });
      this.reGettingtheData()
    }
  };

  handleSaveEditedUserButton = () => {
    this.setState(() => ({ editRow: null }));


      this.props.updateAccount(
        this.props.accounts[this.state.editRow].Id,
        this.props.currentClientId,
        {
        roles: this.state.editedRoles.find(x => x.label === "Owner") !== undefined
          ? ["Owner"]
          : this.state.editedRoles.map(item => {
              return item.value;
            })
      });

    this.reGettingtheData();
  };

  handleDeleteButton = accountId => {
    this.props.removeAccount(accountId,this.props.currentClientId);
    this.reGettingtheData()

  };

  closeModal = () => this.setState(() => ({ modalOpen: false }));

  openModal = () => {
    this.props.fetchExistAccountList(this.props.currentClientId);

    this.setState(() => ({ modalOpen: true, editExistAccount: null, assignRole: null }));
  };

  emailAddressInUse = () =>
    this.props.accountEmailAddressesInUse.indexOf(
      this.state.editedEmailAddress
    ) > -1 &&
    this.props.accountEmailAddressesInUse.indexOf(
      this.state.editedEmailAddress
    ) !== this.state.editRow;

  validateEditedUserAccount = () => {
    let valid =
      !this.emailAddressInUse() &&
      checkEmailAddress(this.state.editedEmailAddress) &&
      this.state.editedFirstName !== "" &&
      this.state.editedLastName !== "";

    if (!this.state.addRow) {
      const originalAccount = this.props.accounts[this.state.editRow];

      valid =
        valid &&
        (this.state.editedFirstName !== originalAccount.user.firstName ||
          this.state.editedLastName !== originalAccount.user.lastName ||
          this.state.editedEmailAddress !== originalAccount.username ||
          this.state.isRoleChange);
    }

    return valid;
  };

  renderLastColumn = (account, index) => {
    return (
      <React.Fragment>
        <button
          type="button"
          className="UserAccounts_icon-button"
          onClick={() => this.handleEditButton(index)}
        >
          <span className="screen-reader">edit</span>
          <SVGIcon icon="edit" />
        </button>{" "}
        <button
          type="button"
          className="UserAccounts_icon-button"
          onClick={() => this.handleDeleteButton(account.Id)}
        >
          <span className="screen-reader">delete</span>
          <SVGIcon icon="delete" />
        </button>
      </React.Fragment>
    );
  };

  render() {

    return (
      <div className="UserAccounts container">
        <div className="Account_section-title-row">
          <h3 className="Account_section-title-row_title">User Accounts</h3>
          <div>
            <Button
              label="Add New User"
              onClick={this.handleAddUserButton}
              color="link"
            />
            <Button
              label="Add Exist User"
              onClick={this.openModal}
              color="link"
            />
          </div>
        </div>
        <LoadingContainer
          className="Account_personal-details content-card"
          loading={this.props.loading}
        >
          <table className="UserAccounts_table">
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Roles</th>
                <th />
              </tr>
            </thead>
            <tbody>
            {this.state.addRow && (
                <UserAccountsEditRow
                  type="add"
                  firstName={this.state.editedFirstName}
                  firstNameOnChange={this.handleFirstNameOnChange}
                  lastName={this.state.editedLastName}
                  lastNameOnChange={this.handleLastNameOnChange}
                  roles={this.state.editedRoles}
                  rolesOnChange={this.handleRolesOnChange}
                  email={this.state.editedEmailAddress}
                  emailOnChange={this.handleEmailOnChange}
                  handleCancelButton={this.handleCancelAddUserButton}
                  handleSaveButton={this.handleSaveAddUserButton}
                  emailAddressValid={this.state.emailAddressValid}
                  emailAddressInUse={this.emailAddressInUse()}
                  inputValid={this.validateEditedUserAccount()}
                />
              )}
              {this.props.accounts.map((account, index) =>
                index === this.state.editRow ? (
                  <UserAccountsEditRow
                    key={account.id}
                    type="edit"
                    firstName={this.state.editedFirstName}
                    firstNameOnChange={this.handleFirstNameOnChange}
                    lastName={this.state.editedLastName}
                    lastNameOnChange={this.handleLastNameOnChange}
                    roles={this.state.editedRoles}
                    rolesOnChange={this.handleRolesOnChange}
                    email={this.state.editedEmailAddress}
                    emailOnChange={this.handleEmailOnChange}
                    handleCancelButton={this.handleCancelButton}
                    handleSaveButton={this.handleSaveEditedUserButton}
                    emailAddressValid={this.state.emailAddressValid}
                    emailAddressInUse={this.emailAddressInUse()}
                    inputValid={this.validateEditedUserAccount()}
                  />
                ) : (
                  <tr key={account.Id}>
                    <td>{account.user.firstName}</td>
                    <td>{account.user.lastName}</td>
                    <td>{account.username}</td>
                    <td>
                      {account.role.roles && account.role.roles.toString()}
                    </td>
                    <td>{this.renderLastColumn(account, index)}</td>
                  </tr>
                )
              )}

            </tbody>
          </table>
        </LoadingContainer>
        <Modal isOpen={this.state.modalOpen} toggle={this.closeModal} centered>
          <ModalHeader toggle={this.closeModal}>
            Add Exist User To Current Client
          </ModalHeader>

          <ModalBody>
            <span className="field">
              <h3 className="field-name" style={{paddingBottom: '.5rem'}}>Exist Accounts</h3>
              <Select
                inputId="criteria-modal-select-organisation"
                className="CriteriaModal_search-organisation-input"
                style={{ width: "3rem" }}
                classNamePrefix="CriteriaModal_search-organisation-input_"
                placeholder="Type to search"
                isDisabled={false}
                isRtl={false}
                isSearchable
                name="organisation"
                options={this.props.existAccount}
                value={ this.state.editExistAccount}
                isMulti
                onChange={e => this.handleExistsAccount(e)}
              />
            </span>

            <span className="field">
              <h3 className="field-name" style={{paddingBottom: '.5rem'}}>Roles</h3>
              <Select
                inputId="criteria-modal-select-organisation"
                className="CriteriaModal_search-organisation-input"
                style={{ width: "3rem" }}
                classNamePrefix="CriteriaModal_search-organisation-input_"
                placeholder="Type to search"
                isDisabled={false}
                isRtl={false}
                isSearchable
                name="organisation"
                options={[
                  {
                    label: "Admin",
                    value: "Admin"
                  },
                  {
                    label: "SuperAdmin",
                    value: "SuperAdmin"
                  },
                  {
                    label: "User",
                    value: "User"
                  },
                  {
                    label: "Partner",
                    value: "Partner"
                  }
                ]}
                value={ this.state.assignRole }
                isMulti
                onChange={e => this.handleAssignRole(e)}
              />
            </span>
          </ModalBody>

          <ModalFooter>
            <Button color="secondary" onClick={this.closeModal}>
              Cancel
            </Button>{" "}
            <Button onClick={() => this.handleAddExistAccountButton()}>Confirm</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

UserAccounts.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  accountEmailAddressesInUse: PropTypes.arrayOf(PropTypes.string).isRequired,
  createNewAccount: PropTypes.func.isRequired,
  updateAccount: PropTypes.func.isRequired,
  removeAccount: PropTypes.func.isRequired,
  //associateRolesWithAccount: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

UserAccounts.defaultProps = {
  loading: false
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAccounts);
